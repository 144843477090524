export function startAVI() {
  var isMobile = typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  if (!isMobile) {
    function vinterListener(event) {
      if (event.origin !== ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.virtualinteractions.com.br') return;
      try {
        eval(event.data);
      } catch (e) {}
    }
    if (window.addEventListener) {
      addEventListener('message', vinterListener, false);
    } else {
      attachEvent('onmessage', vinterListener);
    }
    window.__vi = {};
    window.__vi.license = 6394;
    window.__vi.customVars = [
      {
        'name': 'source',
        'value': '36',
      },
    ];
    (function initAVI() {
      var vi = document.createElement('script');
      vi.type = 'text/javascript';
      vi.defer = true;
      vi.src =
        ('https:' == document.location.protocol ? 'https://' : 'http://') +
        'cdn.virtualinteractions.com.br/pages/celularDireto/tim/js/tracking.js?ms=' +
        new Date().getTime();
      var vis = document.getElementsByTagName('script')[0];
      vis.parentNode.insertBefore(vi, vis);
    })();
  }
}
