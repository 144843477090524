import * as ParamsService from '../params';
import { ufs } from './mocks';

const getUFs = () => ufs;

const getUFSelected = (uf, plan) => {
  if (uf === 'DF') {
    return uf;
  }
  return plan.sku.substr(7, 2);
};

const getUFByParams = () => {
  const regex = /[\?|&]uf=([\w-]+)/;
  return ParamsService.getParamByRegex(regex);
};

export { getUFs, getUFSelected, getUFByParams };
