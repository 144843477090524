const get = (regulations = [], summaries = [], uf) => {
  const location = getDocumentLocation(uf);
  return [...prepareSummaries(summaries, uf), ...filterRegulationsByLocation(regulations, location)];
};

const filterRegulationsByLocation = (regulations, location) =>
  regulations.filter(regulation => regulation.location === location || regulation.location === 'NACIONAL');

const prepareSummaries = (summaries, uf) => {
  return summaries.map(summarie => {
    return { ...summarie, file: summarie.file.replace('{UF}', uf) };
  });
};

const getDocumentLocation = uf => {
  if (uf === 'RJ' || uf === 'ES') {
    return 'RJES';
  } else if (uf === 'SP') {
    return 'SP';
  }
  return 'BR';
};

export { get };
