import React, { useState, useEffect } from 'react';
import Accessibility from 'accessibility-options';

import {
  BannerCta,
  InfoPhrases,
  Loader,
  NetflixBanner,
  NetflixPopup,
  PlansHall,
  RegulationsWrapper,
  Svas,
  TopBar,
  UFSelector,
  PlanDescription,
  PlanCta,
} from '../../shared/components';
import * as UFsService from '../../shared/services/ufs';
import * as RegulationsService from '../../shared/services/regulations';
import * as DataService from '../../shared/services/data';
import * as ParamsService from '../../shared/services/params';
import { startAVI } from '../../shared/services/avi';

const LightPage = () => {
  const pageName = 'light';
  const [isUFSelectorVisible, toggleUFSelector] = useState(false);
  const [isNetflixPopupShow, toggleNetflixPopup] = useState(false);
  const [uf, changeUF] = useState('');
  const [plans, changePlans] = useState(null);
  const [content, changeContent] = useState({});
  const [isLoading, changeLoading] = useState(true);

  useEffect(() => {
    const accessibility = new Accessibility();
    const uf = UFsService.getUFByParams() || '';
    accessibility.init();
    loadData(uf);
    startAVI();
  }, []);

  const loadData = async uf => {
    changeLoading(true);
    const campaign = ParamsService.get('campaign') || 'default';
    const { plans, content } = await DataService.getData(pageName, uf, campaign);
    changeUF(UFsService.getUFSelected(uf, plans[0]));
    changePlans(plans);
    changeContent(content);
    changeLoading(false);
  };

  const handleChangeUF = e => {
    const uf = e.target.value;
    changeUF(uf);
  };

  const handleCloseUFSelector = () => {
    toggleUFSelector(false);
    loadData(uf);
  };

  return (
    <div>
      <TopBar uf={uf} onUFRequest={() => toggleUFSelector(true)} />

      <BannerCta pageName={pageName} />

      <PlanDescription />

      <PlanCta />

      <InfoPhrases
        messages={[
          `Os benefícios de voz/sms ilimitados, 20GB de internet por mês e o tráfego
          ilimitado para os aplicativos de redes sociais/whatsapp ficam garantidos até o final da fidelização`,
          `*Máximo de 5 acessos por colaborador e bônus composto por +2GB
          de Portabilidade +2GB de débito automático + 500MB de conta Online.`,
          '**Mediante permanência de 12 meses.',
          'Acesse o Mapa de Cobertura em nosso site para verificar a abrangência do serviço na sua região.',
          `Se tiver dúvidas sobre a oferta, favor entrar em contato no e-mail
          <a href="mailto:atendimento@compretim.com.br?subject=Dúvidas sobre a
          oferta do Banco do Brasil">atendimento@compretim.com.br</a>.`,
        ]}
      />

      <RegulationsWrapper
        regulations={RegulationsService.get(content.regulations, content.summaries, uf)}
        labelText="Faça download dos regulamentos e sumários"
      />

      <UFSelector
        visible={isUFSelectorVisible}
        handleCloseUFSelector={handleCloseUFSelector}
        selectedUF={uf}
        handleChangeSelect={e => handleChangeUF(e)}
        ufs={UFsService.getUFs()}
      />

      <NetflixPopup visible={isNetflixPopupShow} handleClose={() => toggleNetflixPopup(false)} />

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default LightPage;
